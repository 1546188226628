<template>
  <div class="lang-acc">
    <div
      class="lang-acc-item"
      @click.stop="isOpen = !isOpen"
    >
      <AppText class="flex-grow-1">
        {{ languageObject?.text }}
      </AppText>

      <DropdownArrow :is-open="isOpen" />
    </div>
    <div v-if="isOpen">
      <div
        v-for="(lang, i) in languages.filter((item) => item.value !== languageObject?.value)"
        :key="i"
        class="lang-acc-item"
        :class="{ 'no-select': lang.soon }"
        @click.stop="setLanguage(lang.value)"
      >
        <AppText class="flex-grow-1">
          {{ lang.text }}
        </AppText>
        <AppText size="12px">
          {{ lang.soon }}
        </AppText>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import DropdownArrow from '@/components/Icon/DropdownArrow.vue';

import { emitter } from '@/composables/useBus';
import { useLang } from '@/composables/useLang';

export default {
  name: 'SelectLanguageAcc',
  components: {
    DropdownArrow,
  },
  setup() {
    const { languageObject, languages, setLanguage } = useLang();
    const isOpen = ref(false);

    emitter.on('mobile-menu-change', (val) => {
      if (!val) {
        isOpen.value = false;
      }
    });

    return {
      languageObject,
      languages,
      setLanguage,
      isOpen,
    };
  },
};
</script>

<style lang="scss" scoped>
.lang-acc {
  background: var(--color-white-01);
  border-radius: 4px;
  overflow: hidden;

  .lang-acc-item {
    cursor: pointer;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;

    :deep(.icon) {
      opacity: 1;

      path {
        fill: var(--color-white);
      }
    }

    &:hover {
      background: var(--color-white-005);
    }

    &.no-select {
      cursor: not-allowed;

      &:hover {
        background: none;
      }
    }
  }
}
</style>
