<template>
  <div
    class="sticky"
    :class="{
      'is-visitors-news': item.type === 'NEWS' && isPublic,
      'is-visitors-incident': item.type === 'INCIDENT' && isPublic,
      'is-merchants-news': item.type === 'NEWS' && !isPublic,
      'is-merchants-incident': item.type === 'INCIDENT' && !isPublic,
      hide: isHidden,
    }"
  >
    <ExternalLink
      color="var(--color-black)"
      :href="item.url"
      class="font-medium"
      :has-prepend="Boolean(iconName)"
    >
      <template #prepend>
        <AppIcon
          v-if="!isPublic"
          :key="iconName"
          :name="iconName"
          size="18px"
          style="margin-right: 8px;"
          :opacity="iconName === 'bug' ? 1 : 0.4"
          is-img-tag
        />
      </template>
      <AppText size="13px" :class="{ 'font-medium': isPublic }">
        {{ item.title }}
      </AppText>
    </ExternalLink>
    <div v-if="item.type === 'NEWS'" class="close" @click="onClose">
      <AppIcon name="x-close" :opacity="0.4" size="18px" is-img-tag />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import dayjs from 'dayjs';
import { useIntervalFn } from '@vueuse/core';

import ExternalLink from '@/components/ExternalLink.vue';

import { useStickies } from '@/composables/useStickies';

export default {
  name: 'StickyNotification',
  components: { ExternalLink },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { store } = useStickies();
    const { markStickyHidden } = store;
    const isHidden = ref(false);

    const onClose = () => {
      isHidden.value = true;
      setTimeout(() => {
        // eslint-disable-next-line no-underscore-dangle
        markStickyHidden(props.item._id);
      }, 0);
    };

    const startTimer = (revokeTime) => {
      const { pause } = useIntervalFn(() => {
        const diff = dayjs().diff(dayjs(revokeTime));
        if (diff >= 0) {
          // eslint-disable-next-line no-underscore-dangle
          markStickyHidden(props.item._id);
          pause();
        }
      }, 10000);
    };

    onMounted(() => {
      startTimer(props.item.revokeTime);
    });

    const iconName = computed(() => {
      if (props.item?.audience?.includes('MERCHANTS') && props.item.type === 'NEWS') {
        return 'megaphone';
      }

      if (props.item?.audience?.includes('MERCHANTS') && props.item.type === 'INCIDENT') {
        return 'bug';
      }

      return '';
    });

    return {
      onClose,
      iconName,
      isHidden,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin link-style($base-color, $hover-color) {
  :deep(.text-link) {
    color: $base-color;
    @include transition-base('color');
  }

  :deep(.icon path) {
    fill: $base-color;
    @include transition-base('fill');
  }

  &:hover {
    :deep(.text-link) {
      color: $hover-color;
    }

    :deep(.icon path) {
      fill: $hover-color;
    }
  }
}

.sticky {
  box-shadow: 0px 2px 5px 0px var(--color-black-01);
  background: var(--color-white);
  width: 100%;
  height: 40px;
  border-bottom: 1px solid var(--color-black-01);
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition-base('height');

  &.hide {
    height: 0;
    overflow: hidden;
  }

  :deep(.external-link) {
    flex-grow: 1;
    height: 100%;

    .text-link {
      height: 40px;
    }
  }

  .close {
    cursor: pointer;
    height: 100%;
    aspect-ratio: 1;
    @include flex-center;

    :deep(.icon) {
      opacity: 0.4;
      @include transition-base('opacity');
    }

    &:hover {
      :deep(.icon) {
        opacity: 1;
      }
    }
  }

  &.is-visitors-news {
    :deep(.external-link) {
      padding-left: 40px;
      justify-content: center;
    }

    @include link-style(var(--color-black-06), var(--color-primary));

    @include mq('tablet') {
      position: fixed;
      top: 0;
      z-index: 10;
    }
  }

  &.is-merchants-news {
    height: 50px;
    box-shadow: none;
    background: var(--color-F7F7F7);
    border-bottom: 1px solid var(--color-black-01);

    &:hover {
      background: var(--color-F2F2F2);
    }

    :deep(.external-link) {
      padding-left: 40px;
      justify-content: flex-start;
    }

    @include link-style(var(--color-black), var(--color-black));
  }

  &.is-visitors-incident {
    @include mq('tablet') {
      position: fixed;
      top: 0;
      z-index: 10;
    }
  }

  &.is-visitors-incident, &.is-merchants-incident {
    background: var(--color-FFF1F1);
    border-bottom: 1px solid var(--color-FFD0D0);

    &:hover {
      background: var(--color-F7E9E9);
    }

    :deep(.external-link) {
      justify-content: center;
    }

    @include link-style(rgba(135, 0, 0, 1),rgba(135, 0, 0, 1));
  }

  &.is-merchants-incident {
    height: 50px;
    box-shadow: none;
    :deep(.external-link) {
      padding-left: 40px;
      justify-content: flex-start;
    }
  }
}
</style>
