import { useFetch } from '@/api';
import { useStickies } from '@/composables/useStickies';

const { store: stickiesStore } = useStickies();

const { setPublic } = stickiesStore;

export const getPublicStickies = async () => {
  const { axiosWrapper } = useFetch();

  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/stickies',
  });

  if (isSuccess) {
    setPublic(data);
  }

  return { data, isSuccess };
};
